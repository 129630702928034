<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <e8-logo />
        </b-link>

        <b-card-text class="mb-2 text-center">
          Welcome to the future of trading
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="doLogin"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="username"
                  name="username"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :disabled="true">
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label-for="twoFactor"
              label="Authentication code"
            >
              <validation-provider
                #default="{ errors }"
                name="Authentication code"
                rules="required"
              >
                <b-form-input
                  id="twoFactor"
                  v-model="twoFactor"
                  name="twoFactor"
                  :state="errors.length > 0 ? false:null"
                  placeholder="XXX XXX"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid||appLoginPending"
            >
              <b-spinner
                v-if="appLoginPending"
                small
              />&nbsp;
              <span v-if="!appLoginPending">Sign in</span>
              <span v-if="appLoginPending">Loading...</span>
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardText, BInputGroup, BInputGroupAppend, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { call, sync, get } from 'vuex-pathify'
import Swal from 'sweetalert2'
import E8Logo from '@/components/Logo.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    E8Logo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      loading: false,
    }
  },
  computed: {
    ...sync('auth/credentialsModel@', ['username', 'password', 'twoFactor']),
    ...get('auth', ['appLoginPending']),
    ...get('app', ['apiResponseState']),
    ...sync('app', ['showAppInstallMessage']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod/.test(userAgent)
    }
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone)

    if (isIos() && !isInStandaloneMode()) {
      this.showAppInstallMessage = true
    }
  },
  methods: {
    ...call('auth', ['login']),
    doLogin() {
      this.login().catch(() => {
        if (this.apiResponseState.status === 401) {
          Swal.fire({
            title: 'Invalid credentials',
            text: 'Your accounts credentials are wrong!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style lang="scss" scoped>
  .auth-wrapper::v-deep {
    @media (max-width: map-get($grid-breakpoints, 'sm')) {
      align-items: flex-start;
    }
    .brand-logo {
      margin: 1rem 0;
    }
  }
</style>
